/**
 * Breadcrumbs
 */

import React from 'react';
import classNames from 'classnames';
import s from './Breadcrumbs.module.scss';

class Breadcrumbs extends React.PureComponent {
    render() {
        const { items } = this.props;

        if(items.length <= 0) {
            return null;
        }

        return (
            <div className={s['Breadcrumbs']}>
                <nav className={s['Breadcrumbs__Nav']}>
                    {items.map((item, index) => {
                        if (index < items.length - 1) {
                            return (
                                <div key={index} className={s['Breadcrumbs__Item']}>
                                    <a
                                        className={s['Breadcrumbs__Link']}
                                        href={item.href}>
                                        {item.title}
                                    </a>
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={index}
                                    className={classNames(s['Breadcrumbs__Item'], s['Breadcrumbs__Item--Last'])}>
                                    <span className={classNames(s['Breadcrumbs__Link'], s['Breadcrumbs__Link--Small'])}>
                                        {
                                            item.title.split(' ').length > 3
                                            && ['news', 'editorial'].includes(item.instanceType)
                                                ? `${item.title.split(' ').slice(0, 3).join(' ')}...`
                                                : item.title
                                        }
                                    </span>
                                    <span className={classNames(s['Breadcrumbs__Link'], s['Breadcrumbs__Link--Large'])}>
                                        { item.title}
                                    </span>
                                </div>
                            );
                        }
                    })}
                </nav>
            </div>
        );
    }
}

Breadcrumbs.defaultProps = {
    items: [],
};

export default Breadcrumbs;
