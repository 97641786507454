import SanitizeHTML from 'sanitize-html-react';

// For basic richtext editor
export const sanitizeBasicTags = (text) => {
    return SanitizeHTML(text, {
        allowedTags: [
            'p', 'br', 'wbr', 'b', 'i', 'em', 'strong', 'u', 'a', 'abbr', 'span',
            'sup', 'sub',
        ],
        allowedAttributes: {
            a: ['href', 'target', 'rel', 'title'],
        },
        allowedSchemes: ['http', 'https', 'mailto', 'tel'],
    });
};

// For more advanced richtext editor
export const sanitizeBasicHTML = (text) => {
    return SanitizeHTML(text, {
        allowedTags: [
            'p', 'br', 'wbr', 'b', 'i', 'em', 'strong', 'u', 'a', 'abbr', 'span', 'hr',
            'sup', 'sub', 'del', 's', 'ul', 'ol', 'li', 'blockquote', 'cite', 'iframe',
            'img', 'figure', 'figcaption', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        ],
        allowedAttributes: {
            '*': [
                'class', 'style', 'href', 'target', 'rel', 'title', 'alt', 'width',
                'height', 'aria-*', 'data-*',
            ],
        },
        allowedSchemes: ['http', 'https', 'mailto', 'tel'],
        transformTags: {
            'h1': 'h2',
        },
    });
};

// For richtext editor from Gustav, disallowing any custom style or classes
export const sanitizeArrangementHTML = (text) => {
    return SanitizeHTML(text, {
        allowedTags: [
            'p', 'br', 'wbr', 'b', 'i', 'em', 'strong', 'u', 'a', 'abbr', 'span', 'hr',
            'sup', 'sub', 'del', 's', 'ul', 'ol', 'li', 'blockquote', 'cite', 'h1',
            'h2', 'h3', 'h4', 'h5', 'h6',
        ],
        allowedAttributes: {
            a: ['href', 'target', 'rel', 'title'],
        },
        allowedSchemes: ['http', 'https', 'mailto', 'tel'],
        transformTags: {
            'h1': 'h2',
        },
    });
};

// For text field or excerpts which can have line breaks
export const sanitizeLineBreaks = (text) => {
    return SanitizeHTML(text, {
        allowedTags: ['br', 'wbr'],
    });
};

// For fields that shouldn't contain any html tags
export const sanitizeText = (text) => {
    return SanitizeHTML(text, {
        allowedTags: [],
    });
};
