import React, { useState, useEffect } from 'react';
import s from './ButtonAI.module.scss';
import InformationIcon from 'Assets/inline-svg/information.svg';
import Modal from "./Modal";
import classNames from 'classnames';

const ButtonAI = ({
    title = '',
    description = '',
    aboutText = '',
    products = {},
    contactText = '',
    modifiers = [],
}) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const [isButtonMouseEnter, setButtonMouseEnter] = useState(false);
    const [isButtonMouseLeave, setButtonMouseLeave] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    
        // return () => {
        //   document.removeEventListener('keydown', handleKeyDown);
        // };
    }, []);

    const handleKeyDown = (e) => {
        if (e.keyCode === 27 && isModalOpen) {
            setModalOpen(false);
        }
    };

    const handleButtomMouseEnter = () => {
        setButtonMouseEnter(true)
        setButtonMouseLeave(false)
    }
    
    const handleButtomMouseLeave = () => {
        setButtonMouseEnter(false)
        setButtonMouseLeave(true)
    }

    const buttonClasses = classNames(
        s['ButtonAI__ButtonWrapper'],
        {[s['ButtonAI__ButtonWrapper--Enter']]: isButtonMouseEnter},
        {[s['ButtonAI__ButtonWrapper--Leave']]: isButtonMouseLeave},
        modifiers.map(modifier => ({ [s[`ButtonAI__ButtonWrapper--${modifier}`]]: modifier })),
    );

    return (
        <div className={s['ButtonAI']}>
            {isModalOpen && 
                <Modal
                    title={title}
                    description={description}
                    aboutText={aboutText}
                    contactText={contactText}
                    products={products}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    isModalOpen={isModalOpen}
                    setModalOpen={setModalOpen}
                />
            }
            <div className={buttonClasses}>
                <button 
                    className={s['ButtonAI__Button']}
                    onClick={() => setModalOpen(true)}
                    onMouseEnter={handleButtomMouseEnter}
                    onMouseLeave={handleButtomMouseLeave}
                    type="button"
                >
                    <div className={s['ButtonAI__Tag']}>
                        <span className={s['ButtonAI__TagTheme']}>SENSUS</span>
                        <div className={s['ButtonAI__TagLabel']}>
                            <span>AI</span>
                            <span>AI</span>
                        </div>
                    </div>
                    <div className={s['ButtonAI__ButtonText']}>
                        <p>Sidan innehåller AI-bearbetat innehåll. Läs mer.</p>
                        <InformationIcon
                            className={s['ButtonAI_Icon']}
                            fill="currentColor"
                        />
                    </div>
                </button>
            </div>
            
        </div>
    );
};

ButtonAI.propTypes = {};
ButtonAI.defaultProps = {};

export default ButtonAI;