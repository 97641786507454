/* global CookieScript */
/**
 * EmbedMessage
 */

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'Components/Button';
import s from './EmbedMessage.module.scss';

const EmbedMessage = ({src, category, showEmbed, hideMessage, bgImage}) => {
    useEffect(() => {
        const changeMessage = (e) => {
            const csState = CookieScript?.instance?.currentState();
            if(csState && csState.categories && csState.categories.includes(category)) {
                hideMessage();
            }
        };

        // Hide message when cookie script values are changed
        window.addEventListener('CookieScriptLoaded', changeMessage);
        window.addEventListener('CookieScriptAccept', changeMessage);
        window.addEventListener('CookieScriptAcceptAll', hideMessage);

        return () => {
            window.removeEventListener('CookieScriptLoaded', changeMessage);
            window.removeEventListener('CookieScriptAccept', changeMessage);
            window.removeEventListener('CookieScriptAcceptAll', hideMessage);
        };
    }, []);

    let style = null;
    if(bgImage) {
        style = {backgroundImage: `url('${bgImage}')`};
    }

    // For youtube
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = src.match(regExp);
    const youtubeId = match && match[2].length === 11 ? match[2] : '';
    const youtubeUrl = `https://www.youtube.com/watch?v=${youtubeId}`;
    const isYoutube = youtubeId !== '';
    if(isYoutube && !bgImage) {
        // style = {backgroundImage: `url('https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg')`};
    }

    const isSpotify = src.includes('spotify');
    const spotifyUrl = src.replace('/embed/', '/');

    const isTypeform = src.includes('typeform');

    const url = isYoutube ? youtubeUrl : (isSpotify ? spotifyUrl : src);
    const showText = isYoutube ? 'Visa video' : (
        isTypeform ? 'Visa formulär' : 'Visa spelare'
    );
    const serviceName = isYoutube ? 'Youtube' : (
        isSpotify ? 'Spotify' : (isTypeform ? 'Typeform' : '')
    );
    const linkText = isTypeform ? `Öppna hos ${serviceName} istället` : `Gå till ${serviceName}`;
    const termsUrl = isYoutube ? 'https://www.youtube.com/t/terms' : (
        isSpotify ? 'https://www.spotify.com/us/legal/end-user-agreement/' : (
            isTypeform ? 'https://admin.typeform.com/to/dwk6gt/' : ''
        )
    );

    const title = isTypeform ? 'Detta formulär kan inte visas pga cookie-inställningar' : (
        isYoutube ? 'Här finns en video som inte kan visas pga dina cookie-inställningar' : (
            isSpotify ? 'Här finns en Spotify-playlist som inte kan visas pga. dina cookie-inställningar.' : (
                'Här finns en spelare som inte kan visas pga. dina cookie-inställningar.'
            )
        )
    );
    const msg = isTypeform ? (
        `Här ska egentligen ett formulär från vår leverantör ${serviceName} visas men \
        blockeras på grund av dina cookie-inställningar hos oss. Du kan välja att visa \
        formuläret ändå men godkänner då samtidigt att vi sparar cookies från ${serviceName} \
        enligt deras <a href="${termsUrl}" target="_blank" rel="noopener noreferrer">villkor</a>.`
    ) : (
        `Spelaren kräver godkännande av cookies för tredjepart, ${serviceName}. \
        Genom att visa spelaren godkänner du att cookies sparas från ${serviceName} \
        enligt deras <a href="${termsUrl}" target="_blank" rel="noopener noreferrer">villkor</a>.`
    );

    const classes = classNames(
        s['EmbedMessage'],
        {[s['EmbedMessage--HasBg']]: style !== null},
    );

    return (
        <div className={classes}>
            <div className={s['EmbedMessage__Bg']} style={style} />
            <div className={s['EmbedMessage__Content']}>
                <h2 className={s['EmbedMessage__Title']}>{title}</h2>
                <div
                    className={s['EmbedMessage__Text']}
                    dangerouslySetInnerHTML={{__html: msg}}
                />

                <Button
                    type="button"
                    className={s['EmbedMessage__Button']}
                    onClick={() => [
                        hideMessage(),
                        showEmbed(),
                    ]}
                ><span>{showText}</span></Button>

                <a
                    className={s['EmbedMessage__Link']}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                >{linkText}</a>
            </div>
        </div>
    );
};

EmbedMessage.propTypes = {
    src: PropTypes.string,
    category: PropTypes.string,
    showEmbed: PropTypes.func,
    hideMessage: PropTypes.func,
    bgImage: PropTypes.string,
};

EmbedMessage.defaultProps = {
    src: '',
    category: '',
    showEmbed: () => {},
    hideMessage: () => {},
    bgImage: '',
};

export default EmbedMessage;
